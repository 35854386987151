import { useContext } from 'react';
import { MutationFunction, useMutation, UseMutationOptions } from 'react-query';
import useRestService from './RestService';
import { useAPI } from '../lib/hooks';
import { AppContext } from '../sporttia/AppContext';
import { PostLogin200, PostLoginBody200 } from '../types/api/paths/User';

const resource = '/users';

/**
 * Hook to access all 'users' resources.
 */
export default function useUsersService() {
    const { migrationApis } = useContext(AppContext)!;
    const [request] = useAPI(migrationApis);
    const services = useRestService(resource);

    /**
     * GET /users/:id/agendas request.
     * @param id User ID.
     * @param params Resource parameters to be sent with the request.
     * @returns {Promise<unknown>|*} Return request promise.
     */
    function getUsersAgendas<T>(id: number, params: Record<string, unknown>) {
        return request<T>('GET', `${resource}/${id}/agendas`, params);
    }

    /**
     * POST /tpvs/:id/purchase
     */
    function usePost2FACode(
        mutationOptions?: UseMutationOptions<
            PostLogin200,
            unknown,
            { params?: PostLoginBody200 }
        >,
    ) {
        const mutationFn: MutationFunction<
            PostLogin200,
            { params: PostLoginBody200 }
        > = ({ params }: { params: PostLoginBody200 }) =>
            request('POST', `/login`, params);

        return useMutation(mutationFn, mutationOptions);
    }

    return {
        ...services,
        getUsersAgendas,
        usePost2FACode,
    };
}
