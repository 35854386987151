import React, { useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import {
    AppContext,
    SttButtonSave,
    SttButtonDelete,
    SttFormDate,
    SttFormPrice,
    SttFormSelect,
    SttFormText,
    SttFormTextarea,
    SttSelectFixedInterval,
    SttFormCheck,
    SttSelectGroup,
    SttButtonRecover,
} from '../../../sporttia/all';
import HandlePaymentsModal from './HandlePaymentsModal';
import { parsePrice, subsetObject } from '../../../lib/utils';
import { useConfirmDialog, useTranslations } from '../../../lib/hooks';
import GroupDiscountsList from './GroupDiscountsList';
import translations from '../../../translations';
import constants from '../../../config/constants';
import SelectGroupParent from './SelectGroupParent';

/**
 * GroupDetails: component to visualize and save the group's details. Sports groups cannot be modified.
 * Object item: group to view details from
 * <Object>array parents: list of possible parent groups (based on year)
 */
export default function GroupDetails({ item, onSave, onDelete }) {
    const cxt = useContext(AppContext);

    const [deleteModal, setDeleteModal] = useState(false);
    const [openConfirmDialog, , confirmDialog] = useConfirmDialog();

    const [groupForm, setGroupForm] = useState(item);

    const shouldUpdateDates = groupForm.end !== item.end;

    function handleChange({ name, value }) {
        setGroupForm((prev) => ({ ...prev, [name]: value }));
    }

    const { translate } = useTranslations();

    function saveGroup(updateUserDates) {
        const fields = `name,description,${
            groupForm.type === constants.group.types.family.name
                ? `maxBeneficiaries`
                : `maxMembers`
        },idParent,ini,end,enrollmentPrice,feePrice,feeInterval,purchaseOnline,expiration,feeTaxConceptCode,signupTaxConceptCode`;

        const params = subsetObject(groupForm, fields);
        params.enrollmentPrice = parsePrice(params.enrollmentPrice);
        params.feePrice = parsePrice(params.feePrice);
        params.idMandatoryGroup = groupForm.mandatoryGroup?.id
            ? groupForm.mandatoryGroup.id
            : '';
        params.idParent = groupForm.parent?.id ?? '';
        if (params.feeInterval === '0000-00-00 00:00:00')
            params.feeInterval = '';
        if (updateUserDates) {
            params.updateUserPeriod = true;
        }

        cxt.api('PUT', `/groups/${item.id}`, {
            params,
            success: (response) => {
                if (response.group) {
                    cxt.showMessage('S', cxt.t(translations.generic.saved));
                    onSave?.(response.group);
                }
            },
        });
    }

    function deleteGroup(mode) {
        // Without a 'mode' specified, we ask the user for confirmation before nuking the group.
        cxt.api('DELETE', `/groups/${item.id}`, {
            confirmation: mode === undefined,
            params: mode
                ? {
                      paymentProcess: mode,
                  }
                : {},
            success: (response) => {
                if (response.group) {
                    if (typeof onDelete === 'function') {
                        onDelete(response.group);
                    }
                }
            },
        });
    }

    function recoverGroup() {
        cxt.api('PUT', `/groups/${item.id}/recover`, {
            confirmation: true,
            success: (response) => {
                if (response.group) {
                    onSave?.(response.group);
                }
            },
        });
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item container md={7} sm={7} xs={12}>
                    <Grid spacing={3} container>
                        <Grid item md={12} sm={12} xs={12}>
                            <SttFormText
                                name="name"
                                caption={cxt.t(translations.generic.name)}
                                defVal={groupForm.name}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            {groupForm.type !==
                            constants.group.types.family.name ? (
                                <SttFormText
                                    name="maxMembers"
                                    caption={cxt.t('MaxUsers')}
                                    defVal={groupForm.maxMembers}
                                    onChange={handleChange}
                                />
                            ) : (
                                <SttFormText
                                    name="maxBeneficiaries"
                                    caption={cxt.t(
                                        translations.generic.beneficiaries,
                                    )}
                                    defVal={groupForm.maxBeneficiaries}
                                    onChange={handleChange}
                                />
                            )}
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            <SttFormSelect
                                name="type"
                                caption={cxt.t(translations.generic.type)}
                                disabled
                                defVal={groupForm.type}
                                options={[
                                    {
                                        caption: cxt.t(
                                            constants.group.types.normal
                                                .translationCode,
                                        ),
                                        value: constants.group.types.normal
                                            .name,
                                    },
                                    {
                                        caption: cxt.t(
                                            constants.group.types.abonados
                                                .translationCode,
                                        ),
                                        value: constants.group.types.abonados
                                            .name,
                                    },
                                    {
                                        caption: cxt.t(
                                            constants.group.types.sport
                                                .translationCode,
                                        ),
                                        value: constants.group.types.sport.name,
                                    },
                                    {
                                        caption: cxt.t(
                                            constants.group.types.family
                                                .translationCode,
                                        ),
                                        value: constants.group.types.family
                                            .name,
                                    },
                                ]}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                            <SelectGroupParent
                                groupId={item.id}
                                groupParent={groupForm.parent}
                                onChange={handleChange}
                                caption={cxt.t('ParentGroup')}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SttFormTextarea
                                name="description"
                                caption={cxt.t(
                                    translations.generic.description,
                                )}
                                defVal={groupForm.description}
                                onChange={handleChange}
                            />
                        </Grid>

                        {/* --- Date ini --- */}
                        <SttFormDate
                            grid
                            md={6}
                            sm={12}
                            xs={12}
                            name="ini"
                            caption={cxt.t('PeriodFrom')}
                            defVal={groupForm.ini}
                            onChange={handleChange}
                        />

                        {/* --- Date end --- */}
                        <SttFormDate
                            grid
                            md={6}
                            sm={12}
                            xs={12}
                            name="end"
                            caption={cxt.t('Until')}
                            defVal={groupForm.end}
                            onChange={handleChange}
                        />

                        {/* --- Mandatory group --- */}
                        <SttSelectGroup
                            grid
                            md={6}
                            sm={12}
                            xs={12}
                            name="mandatoryGroup"
                            caption={cxt.t(translations.generic.mandatoryGroup)}
                            defVal={groupForm.mandatoryGroup}
                            onChange={handleChange}
                        />

                        <Grid item md={6} sm={12} xs={12}>
                            <SttSelectFixedInterval
                                caption={cxt.t('generateFeesEvery')}
                                name="feeInterval"
                                defVal={
                                    groupForm.feeInterval ??
                                    '0000-00-00 00:00:00'
                                }
                                options={[
                                    'NO PERIOD',
                                    '1 WEEK',
                                    '2 WEEK',
                                    '1 MONTH',
                                    '2 MONTH',
                                    '3 MONTH',
                                    '4 MONTH',
                                    '6 MONTH',
                                    '1 YEAR',
                                ]}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item md={6} sm={12} xs={12}>
                            <SttFormPrice
                                name="feePrice"
                                caption={cxt.t('feePrice')}
                                defVal={groupForm.feePrice}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            <SttFormPrice
                                name="enrollmentPrice"
                                caption={cxt.t(
                                    translations.generic.inscription,
                                )}
                                defVal={groupForm.enrollmentPrice}
                                onChange={handleChange}
                            />
                        </Grid>

                        <Grid item md={6} sm={6} xs={12}>
                            <SttSelectFixedInterval
                                caption={cxt.t(translations.generic.expiration)}
                                name="expiration"
                                defVal={groupForm.expiration}
                                options={['!FORTNIGHT']}
                                onChange={handleChange}
                            />
                        </Grid>
                        {Object.values(constants.orgt.scIds).includes(
                            cxt?.sc?.id,
                        ) && (
                            <Grid item md={6} sm={6} xs={6}>
                                <SttFormSelect
                                    name="feeTaxConceptCode"
                                    caption={cxt.t(
                                        translations.orgt.feeTaxConcept,
                                    )}
                                    defVal={
                                        groupForm?.feeTaxConceptCode ??
                                        constants.orgt.taxCodes.noOption
                                    }
                                    options={[
                                        {
                                            caption: translate(
                                                translations.orgt.noOption,
                                            ),
                                            value: null,
                                        },
                                        {
                                            caption: translate(
                                                translations.orgt.feePass,
                                            ),
                                            value: constants.orgt.taxCodes
                                                .feePassCode,
                                        },
                                        {
                                            caption: translate(
                                                translations.orgt
                                                    .feeRoomActivity,
                                            ),
                                            value: constants.orgt.taxCodes
                                                .feeRoomActivity,
                                        },
                                        {
                                            caption: translate(
                                                translations.orgt
                                                    .feeAquaticActivity,
                                            ),
                                            value: constants.orgt.taxCodes
                                                .feeAquaticActivity,
                                        },
                                        {
                                            caption: translate(
                                                translations.orgt
                                                    .feeSchoolActivity,
                                            ),
                                            value: constants.orgt.taxCodes
                                                .feeSchoolActivity,
                                        },
                                        {
                                            caption: translate(
                                                translations.orgt.assurance,
                                            ),
                                            value: constants.orgt.taxCodes
                                                .assurance,
                                        },
                                    ]}
                                    onChange={handleChange}
                                />
                            </Grid>
                        )}
                        {Object.values(constants.orgt.scIds).includes(
                            cxt?.sc?.id,
                        ) && (
                            <Grid item md={6} sm={6} xs={6}>
                                <SttFormSelect
                                    name="signupTaxConceptCode"
                                    caption={cxt.t(
                                        translations.orgt.signupTaxConcept,
                                    )}
                                    defVal={
                                        groupForm?.signupTaxConceptCode ??
                                        constants.orgt.taxCodes.noOption
                                    }
                                    options={[
                                        {
                                            caption: translate(
                                                translations.orgt.noOption,
                                            ),
                                            value: null,
                                        },
                                        {
                                            caption: translate(
                                                translations.orgt.enrollment,
                                            ),
                                            value: constants.orgt.taxCodes
                                                .enrollment,
                                        },
                                    ]}
                                    onChange={handleChange}
                                />
                            </Grid>
                        )}
                        {/* --- Purchase online --- */}
                        {groupForm.type ===
                            constants.group.types.abonados.name && (
                            <SttFormCheck
                                grid
                                md={12}
                                sm={12}
                                xs={12}
                                name="purchaseOnline"
                                caption={cxt.t('page.sc.bono.onlinePurchase')}
                                checked={groupForm.purchaseOnline}
                                onChange={handleChange}
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid item container sm={5} xs={12}>
                    <Grid item xs={12}>
                        <GroupDiscountsList groupId={item.id} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                {/* --- Buttons --- */}
                {groupForm.type !== constants.group.types.sport.name &&
                    !item.trash && (
                        <Grid item xs={12} sm={6} md={3}>
                            <SttButtonSave
                                fullWidth
                                onClick={() => {
                                    if (shouldUpdateDates) {
                                        openConfirmDialog({
                                            title: `${cxt.t(
                                                translations.generic.modify,
                                            )} ${cxt.t(
                                                translations.generic.date,
                                            )}`,
                                            content: (
                                                <div>
                                                    {cxt.t(
                                                        'page.groups.modifyUserDates?',
                                                    )}
                                                </div>
                                            ),
                                            onConfirm: () => saveGroup(true),
                                            onCancel: () => saveGroup(),
                                        });
                                    } else {
                                        saveGroup();
                                    }
                                }}
                            />
                        </Grid>
                    )}

                {item.trash ? (
                    <Grid item xs={12} sm={6} md={3}>
                        <SttButtonRecover fullWidth onClick={recoverGroup} />
                    </Grid>
                ) : (
                    <Grid item xs={12} sm={6} md={3}>
                        <SttButtonDelete
                            fullWidth
                            onClick={() => {
                                if (
                                    item.type ===
                                    constants.group.types.sport.name
                                ) {
                                    deleteGroup();
                                } else {
                                    setDeleteModal(true);
                                }
                            }}
                        />
                    </Grid>
                )}
            </Grid>

            {/* --- Modal asking before deleting --- */}
            {deleteModal && (
                <HandlePaymentsModal
                    warningText={cxt.t('sc.groups.delete.howToHandlePayments')}
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                    onAccept={(mode) => deleteGroup(mode)}
                />
            )}

            {confirmDialog}
        </>
    );
}
